import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Homepage from './components/pages/homepage/Homepage';
import MidiPage from './components/pages/midi-page/MidiPage';
import BadBrowser from './components/pages/bad-browser/BadBrowser';
import Error from "./components/pages/error/Error";
import { AuthProvider } from "./Auth";
import PrivateRoute from './PrivateRoute';

class App extends Component {
  render() {
    return (
      <AuthProvider value={this.state}>
        <BrowserRouter>
          <Switch>
              <Route path="/" component={Homepage} exact/>
              <PrivateRoute path="/keyboard" component={MidiPage} exact/>
              <PrivateRoute path="/invalid-browser" component={BadBrowser} exact/>
              <Route render={(props) => <Error {...props}/>}/>
          </Switch>
        </BrowserRouter>
      </AuthProvider>
    );
  }
}

export default App;
