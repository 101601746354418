import React, { useEffect, useState } from 'react';
import Devices from './devices/Devices';
import Octave from './octave/Octave';
import { detect } from '@tonaljs/chord-detect';
import { sleep, toNote, keyToString } from './../../resources/Functions';
import { Redirect } from 'react-router-dom';
import './Keyboard.css';

const scales = {
    1: 2.5,
    2: 2.5,
    3: 2.5,
    4: 2,
    5: 1.75,
    6: 1.45,
    7: 1.25
}

const storageObj = {};
for (let i = 21; i <= 108; i++) {
    storageObj[keyToString(i)] = false;
}

const Keyboard = props => {
    const { 
        className, 
        octaveN,        // number of octaves displayed
        size,           // number to adjust set scales by
        showChord,      // Boolean whether or not chord identification is displayed
        usePedal,       // Boolean whether or not pedal is useable
        onChordChange   // function to pass chord identification out of keyboard
    } = props;
    // sizing scale applied to the keyboard based on the number of octaves and size determined 
    // by the user.
    const scale = scales[octaveN] + size * 0.05;
    // used to make sure we don't have an infinite loop while loading and changing devices
    const [loading, setLoading] = useState(true);
    // holds object of devices that is used everywhere
    const [devices, setDevices] = useState({});
    // used when devices is changed, updated a half second later
    const [deviceHistory, setDeviceHistory] = useState({});
    // a simplified object showing the singular device that is connected
    const [deviceConnected, setDeviceConnected] = useState({
        name: '',
        id: ''
    });
    // all data needed to highlight notes on the keyboard
    const [noteData, setNoteData] = useState({
        // boolean stating whether the pedal is depressed
        pedalPressed: false,
        // array that holds midi keys for notes actually depressed
        notes: storageObj,
        // array that holds midi keys for notes displayed (different than above because of pedal)
        display: storageObj,
        // transfer props into state the changeNote function can see
        showChord: showChord,
        usePedal: usePedal,
    })
    // used to show devices modal
    const [showModal, setShowModal] = useState(true)
    // holds the midi channel in use
    const [channel, setChannel] = useState(0);
    // Used to send the user elsewhere when there's a bad browser
    const [redirect, setRedirect] = useState({
        send: false,
        path: ''
    })

    // change noteData if usePedal changes
    useEffect(() => {
        setNoteData(prev => ({...prev, usePedal: usePedal}))
    }, [usePedal])

    // change noteData if showChord changes
    useEffect(() => {
        setNoteData(prev => ({...prev, showChord: showChord}))
    }, [showChord])

    // changes the chord identification when the notes array is changed
    useEffect(() => {
        if (showChord) {
            let prop = usePedal ? 'display' : 'notes';
            let arr = [];
            Object.keys(noteData[prop]).forEach(key => {
                if (noteData[prop][key]) {
                    arr.push(toNote(parseInt(key)))
                }
            })
            let chrd = detect(arr)[0];
            if (chrd != null) {
                chrd = chrd.replace('M', '');
            }
            onChordChange(chrd);
        }
    }, [noteData, onChordChange, showChord, usePedal])

    // determines which octaves should be displayed based on the number of octaves
    const octaveOrder = {
        1: [4],
        2: [3,4],
        3: [3,4,5],
        4: [2,3,4,5],
        5: [2,3,4,5,6],
        6: [1,2,3,4,5,6],
        7: [1,2,3,4,5,6,7]
    }

    const setKeyUp = (prev, key) => {
        return prev.usePedal ? {
            ...prev, 
            notes: {
                ...prev.notes,
                [keyToString(key)]: false
            },
            display: prev.pedalPressed ? prev.display : {
                ...prev.display,
                [keyToString(key)]: false
            }
        } : {
            ...prev,
            notes: {
                ...prev.notes,
                [keyToString(key)]: false
            }
        };
    }

    const setKeyDown = (prev, key) => {
        return prev.usePedal ? {
            ...prev, 
            notes: {
                ...prev.notes,
                [keyToString(key)]: true
            },
            display: {
                ...prev.display,
                [keyToString(key)]: true
            }
        } : {
            ...prev,
            notes: {
                ...prev.notes,
                [keyToString(key)]: true
            }
        };
    }

    // update the notes array based on midi events
    const updateNotes = (command, key, ch) => {
        if (command === 128 + ch || command === 144 + ch) {
            setNoteData(prev => {
                if (command === 128 + ch) {
                    // key up
                    return setKeyUp(prev, key);
                } else if (command === 144 + ch) {
                    if (!prev.notes[keyToString(key)]) {
                        // note isn't already pressed
                        // key down
                        // This if statement is to speed things up when not identifying chords
                        return setKeyDown(prev, key);
                    } else {
                        // note is already pressed
                        // key up
                        return setKeyUp(prev, key);
                    }
                }
            })
        }
    }

    const updatePedal = (value) => {
        if (value < 30) {
            setNoteData(prev => {
                return prev.usePedal ? {
                    ...prev, 
                    pedalPressed: false, 
                    display: prev.notes
                } : prev
            })
        } else {
            if (!noteData.pedalPressed) {
                setNoteData(prev => {
                    return prev.usePedal ? {
                        ...prev, 
                        pedalPressed: true
                    } : prev
                });
            }
        }
    }

    // // For debugging
    // useEffect(() => {
    //     console.log("pedal pressed", noteData.pedalPressed)
    // }, [noteData.pedalPressed])

    // // More debugging
    // useEffect(() => {
    //     let letters = noteData.notes.map(key => toNote(key))
    //     console.log(letters)
    // }, [noteData.notes])

    // open a connection to a midi device and attach the event listenr. 
    const connectToDevice = (device, ch) => {
        console.log('Connecting to device', device);
        setChannel(ch);
        // used for proper indexing with the command numbers and different channels
        const newch = ch - 1;
        setDeviceConnected({
            name: device.name + ' (' + device.manufacturer + ')',
            id: device.id
        });
        device.onmidimessage = m => {
            const [command, key, velocity] = m.data;
            if (command === 176 && key === 64) {
                updatePedal(velocity);
            } else {
                updateNotes(command, key, newch);
            }
            
        }
    }

    // Fired when component loads
    useEffect(() => {
        const updateDevices = inputs => {
            let dObj = {};
            inputs.forEach(input => {
                dObj[input.id] = input;
            })
            setDevices(dObj);
        }

        try {
            navigator.requestMIDIAccess().then(access => {
                console.log('access', access);
                updateDevices(Array.from(access.inputs.values()));
                access.onstatechange = e => {
                    updateDevices(Array.from(access.inputs.values()));
                }
            })
        } catch (error) {
            setRedirect({
                send: true,
                path: '/invalid-browser'
            })
        }
    }, [])

    // Triggered when available midi devices change status
    useEffect(() => {
        if (Object.keys(devices).length !== Object.keys(deviceHistory).length && !loading) {
            window.location.reload();
        }
        if (loading) {
            sleep(1000).then(() => setLoading(false));
        }
        console.log('devices changed', devices)
        sleep(500).then(() => {
            setDeviceHistory(devices);
        })
    }, [devices])

    if (redirect.send) {
        return <Redirect to={redirect.path} />
    }

    return (
        <div className={className + ' kb-wrapper'} style={{padding: (10 * scale).toString() + 'px'}}>
            <div className='kb-text'>
                <span>Device Connected: </span>
                <span 
                    style={{
                        color: deviceConnected.name === '' ? 'red' : 'rgb(82, 255, 82)',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        window.location.reload();
                    }}
                    title="Open Device Menu"
                >{deviceConnected.name === '' ? 'None' : deviceConnected.name}</span>
                <div className="kb-channel">Midi Channel: {deviceConnected.name === '' ? '' : channel}</div>
            </div>
            <div className='keyboard'>
                {octaveOrder[octaveN].map(n => {
                    return <Octave 
                                key={n}
                                n={n + 1} 
                                scale={scale}
                                usePedal={usePedal}
                                display={usePedal ? noteData.display : noteData.notes}
                                notes={usePedal ? noteData.notes : {}}
                            />
                })}
            </div>
            <Devices
                show={showModal}
                closeFunc={() => setShowModal(false)}
                devices={devices}
                onConnect={(device, ch) => connectToDevice(device, ch)}
            />
        </div>
    )
}

export default Keyboard;