export const copyStringToClipboard = str => {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
}

export const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const toNote = n => {
    let base = n % 12;
    switch (base) {
        case 0:
            return 'C';
        case 1:
            return 'Db';
        case 2:
            return 'D';
        case 3:
            return 'Eb';
        case 4:
            return 'E';
        case 5:
            return 'F';
        case 6:
            return 'Gb';
        case 7:
            return 'G';
        case 8:
            return 'Ab';
        case 9:
            return 'A';
        case 10:
            return 'Bb';
        case 11:
            return 'B';
        default:
            return '';
    }
}

// used for chords.ts naming conventions
export const formatChord = str => {
    let M = / Major */i;
    let m = / Minor */i;
    let hdim = / half-diminished */i;
    let dim = / diminished */i;
    let d = / dominant */i;
    let s = / suspended */i;
    if (M.test(str)) {
        return str.replace(M, 'maj');
    } else if (m.test(str)) {
        return str.replace(m, 'm');
    } else if (hdim.test(str)) {
        return str.replace(hdim, 'ø')
    } else if (dim.test(str)) {
        return str.replace(dim, 'dim')
    } else if (d.test(str)) {
        return str.replace(d, '');
    } else if (s.test(str)) {
        return str.replace(s, 'sus');
    } else {
        return str;
    }
}

export const keyToString = key => {
	let str = key.toString();
    if (str.length === 2) {
  	    str = "0" + str;
    }
    return str;
}