import React from 'react';
import './VideoBanner.css';

const VideoBanner = props => {
    const { title, subtitle } = props;

    return (
        <div className="vb-main">
            <div className="vb-video-container">
                <video autoPlay muted loop className="vb-video" aria-label="App Demo">
                    <source src="https://i.imgur.com/cBZsSjo.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="vb-title-anchor">
                <div className="vb-title-container">
                    <h1 className="vb-title app-font">{title}</h1>
                    <h2 className="vb-subtitle app-font">{subtitle}</h2>
                </div>
            </div>
        </div>
    )
}

export default VideoBanner;