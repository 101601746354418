import React, { useState } from 'react';
import NavBar from '../../nav-bar/NavBar';
// import Footer from './../../footer/Footer';
import VideoBanner from './video-banner/VideoBanner';
import { Button } from 'react-bootstrap';
import { fireAuth } from './../../../Fire';
import './Homepage.css';

const Homepage = () => {
    const [triggerAuth, setTriggerAuth] = useState({
        value: '',
        trigger: false,     // boolean to toggle to trigger auth
        path: ''            // path to follow after authentication
    })
    const loggedIn = fireAuth.currentUser != null;

    return (
        <>
            <NavBar triggerAuth={triggerAuth} />
            <div className="hp-page" data-testid="homepage-page">
                <VideoBanner 
                    title={(
                        <><i className="icon-midi-display hp-banner-logo" />Midi Display</>
                    )}
                    subtitle="Highlight your piano right in your browser"
                />
                <div className='hp-main-desc-section'>
                    <p className="hp-main-desc-text">
                        <span className="app-bold"><i className="icon-midi-display" /> Midi Display</span> is a stronger, simpler 
                        piano visualizer. It displays a piano keyboard that highlights notes played by an external MIDI controller 
                        and is great for people teaching virtual piano lessons or making simple online videos.
                    </p>
                </div>
                <div className="hp-section hp-try-section">
                    <div className="hp-try-content-wrapper">
                        <h2 className="hp-section-title app-font">
                            {loggedIn ? "Welcome to Midi Display!" : "Try it Free today"}
                        </h2>
                        {!loggedIn && <p className="hp-section-text">Works best on desktop</p>}
                        <div className="hp-btn-wrapper">
                            {loggedIn ? <Button
                                variant="info"
                                className="hp-link-btn"
                                href="/keyboard"
                            >
                                <span className="hp-link-btn-text">Open Keyboard</span>
                            </Button> : <Button
                                variant="info"
                                className="hp-link-btn"
                                onClick={() => setTriggerAuth(prev => ({
                                    value: 'ca',
                                    trigger: !prev.trigger,
                                    path: '/keyboard'
                                }))}
                            >
                                <span className="hp-link-btn-text">Try It</span>
                            </Button>}
                        </div>
                    </div>
                </div>
                <div className="hp-section hp-feature-container">
                    <div className="hp-feature">
                        <h2 className="hp-feature-title app-font">Display in Stunning Realism</h2>
                        <div className="hp-feature-content">
                            <div className="hp-demo-vid">
                                <div className='hp-embed-container'>
                                    <iframe src="https://www.youtube.com/embed/F-S8gGQNBSc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                            <div className="hp-feature-desc-blocks">
                                <div className="hp-feature-desc-block">
                                    <h3 className="hp-feature-desc-title app-font">Different Colors for Sustained Notes</h3>
                                    <p className="hp-feature-desc-text">
                                        Midi Display has the option to use input from the sustain pedal to create a look that
                                        feels natural and makes it easy for the viewer to see exactly how the pianist is
                                        crafting their music.
                                    </p>
                                </div>
                                <div className="hp-feature-desc-block">
                                    <h3 className="hp-feature-desc-title app-font">Display Chord Names</h3>
                                    <p className="hp-feature-desc-text">
                                        Midi Display uses artificial intelligence to determine the name of the chord
                                        you're playing at any given moment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hp-section hp-feature-container">
                    <div className="hp-feature">
                        <h2 className="hp-feature-title app-font">Simple to Use</h2>
                        <div className="hp-feature-content">
                            <div className="hp-demo-vid">
                                <div className='hp-embed-container'>
                                    <iframe src="https://www.youtube.com/embed/b3DZthBpHjk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                            <div className="hp-feature-desc-blocks">
                                <div className="hp-feature-desc-block">
                                    <h3 className="hp-feature-desc-title app-font">Quickly Connect Midi Devices</h3>
                                    <p className="hp-feature-desc-text">
                                        Midi Display allows you to connect any midi device currently connected to your
                                        computer. It also allows you to select any one of the 16 midi channels available.
                                    </p>
                                </div>
                                <div className="hp-feature-desc-block">
                                    <h3 className="hp-feature-desc-title app-font">Connect Your System's Internal Midi Device</h3>
                                    <p className="hp-feature-desc-text">
                                        You can even use midi signals from other applications on your computer like Ableton Live
                                        to display midi files in real time.
                                    </p>
                                </div>
                                <div className="hp-feature-desc-block">
                                    <h3 className="hp-feature-desc-title app-font">Share Your Screen</h3>
                                    <p className="hp-feature-desc-text">
                                        Midi Display works great with the screen sharing features in many video chat platforms.
                                        And if you run a live DAW in the background like Mainstage or Abelton Live, you can share
                                        audio through some video chat platforms as well.
                                    </p>
                                </div>
                                <div className="hp-feature-desc-block">
                                    <h3 className="hp-feature-desc-title app-font">Screen Record</h3>
                                    <p className="hp-feature-desc-text">
                                        Midi Display also works great for screen recording. Change the number of octaves displayed
                                        or the size of the keyboard to make it look exactly how you want. Record your screen and
                                        maintain the flexibility of adding your audio and other visual effects in post-production.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default Homepage
