import React from 'react';
import { Button } from 'react-bootstrap';
import './Warning.css';

/*
    Props:
    className: class applied to outermost element for positional purposes
    title: jsx element for the title
    text: array of jsx elements to make paragraphs
*/
const Warning = props => {
    const { className, title } = props;
    const text = props.text === undefined ? [] : props.text;

    return <>
        <div className={"w-page " + className}>
            <div className="w-content">
                <h1 className="w-title app-font">
                    <i className="icon-midi-display" /> {title}
                </h1>
                {text.map((par, i) => (
                    <p key={i} className="w-description">
                        {par}
                    </p>
                ))}
                <div className="w-btn-wrapper"><Button href="/" variant="info">Go Back</Button></div>
            </div>
        </div>
    </>
}

export default Warning;