import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import $ from 'jquery';
import './Devices.css';

const Devices = props => {
    const { show, closeFunc, devices, onConnect } = props;
    const [dSelected, setDSelected] = useState(null);
    const [channels, setChannels] = useState(() => {
        let obj = {};
        Object.values(devices).forEach(device => {
            obj[device.id] = 1;
        })
        return obj;
    })

    const handleDone = () => {
        const ch = channels[dSelected] == null ? 1 : parseInt(channels[dSelected]);
        onConnect(devices[dSelected], ch)
        closeFunc()
    }

    // Listen for enter key to close modal
    $(document).keyup(event => {
        if (event.which === 13 && show && dSelected != null) {
            handleDone();
        }
    });

    return (
        <>
            <Modal
                show={show} 
                onHide={closeFunc}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter' className='dm-text'>Choose Device</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.values(devices).length === 0 ? <>
                        <div className="dm-spinner-container">
                            <Spinner animation="border" variant="info" />
                        </div>
                    </> : <>
                        <p className='dm-text'>Click a device to open its connection.</p>
                        {Object.values(devices).map((device, i) => {
                            return (
                                <div className='dm-btn-wrapper' key={i}>
                                    <Button
                                        key={i}
                                        className='dm-btn'
                                        onClick={() => {
                                            // onConnect(device, channels[device.id])
                                            // closeFunc();
                                            setDSelected(device.id);
                                        }}
                                        variant={device.id === dSelected ? 'info' : 'dark'}
                                    >{device.name} ({device.manufacturer})</Button>
                                    <Form.Select
                                        className='dm-select'
                                        onChange={e => {
                                            setChannels(prev => ({...prev, [device.id]: parseInt(e.target.value.replace(/ch /i, ''))}))
                                        }}
                                        value={'Ch ' + channels[device.id]}
                                    >
                                        {Array.from({length: 16}, (_, i2) => i2 + 1).map((ch, index) => {
                                            return <option key={index}>Ch {ch}</option>
                                        })}
                                    </Form.Select>
                                </div>
                            )
                        })}
                    </>}
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant='secondary' 
                        onClick={closeFunc}
                    >Close</Button>
                    <Button 
                        variant={dSelected === null ? 'secondary' : 'info'} 
                        onClick={handleDone}
                        disabled={dSelected === null}
                    >Connect</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Devices;