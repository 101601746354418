import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { sleep } from '../../../resources/Functions';
import CreateAccount from './create-account/CreateAccount';
import LogIn from './log-in/LogIn';
import './AuthModal.css';

/*
    Props:
    - handleClose: function to close the modal and remove it from the dom
    - show: boolean to determine if modal is shown
    - authType: either 'ca' or 'li' for 'create account' and 'log in' respectively
    - donePath: path to follow when authenticated
*/
const AuthModal = props => {
    const { handleClose, donePath } = props;
    const [show, setShow] = useState(props.show)
    // either 'login' or 'createaccount'
    const [authType, setAuthType] = useState(props.authType)

    useEffect(() => {
        setShow(props.show)
    }, [props.show])

    // change internal state and then wait a second before passing word
    // to the parent component
    const handleHide = () => {
        setShow(false);
        sleep(500).then(() => {
            handleClose();
        })
    }

    const handleSwitch = to => {
        setAuthType(to);
    }

    let content = <></>
    if (authType === 'ca') {
        content = <CreateAccount 
            handleSwitch={() => handleSwitch('li')} 
            donePath={donePath}
        />
    } else if (authType === 'li') {
        content = <LogIn 
            handleSwitch={() => handleSwitch('ca')} 
            donePath={donePath}
        />;
    } else {
        throw new Error("'authType' needs to be 'ca' or 'li'!!");
    }

    return <>
        <Modal
            show={show}
            onHide={handleHide}
            // size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="app-modal auth-modal"
        >
            {content}
        </Modal>
    </>
}

export default AuthModal