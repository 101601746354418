import React from 'react';
import { Button } from 'react-bootstrap';
import Warning from '../../warning/Warning';
import './BadBrowser.css';

const BadBrowser = () => {

    return <>
        <Warning 
            title={
                <>
                    Browser Not Supported
                </>
            }
            text={[
                <>
                    Unfortunately your browser does not support the Web MIDI API.
                </>,
                <>
                    <i className="icon-midi-display" /> Midi Display will work in Chrome, 
                    Edge, Opera, and any other browsers built on them. 
                </>
            ]}
        />
    </>
}

export default BadBrowser;