import React from 'react';
import { keyToString, toNote } from '../../../resources/Functions';
import './Octave.css';

const highlightColorPedal = 'rgb(0, 215, 211)';
const highlightColor = 'rgb(50, 255, 251)';
const highlightColorPedal2 = 'rgb(0, 160, 158)';
const highlightColor2 = 'rgb(50, 200, 198)';

const Octave = props => {
    let { 
        n,          // key to designate which octave this is
        scale,      // number to multiply dimensions by
        display,    // notes that are displayed, including pedal-held notes
        notes,      // notes that are actually depressed
        usePedal    // whether or not the pedal is useable
    } = props;
    if (n < 0 || n > 8) {
        alert("Invalid Octave Number")
    }
    let base = n * 12;

    const keyDimensions = {
        c: {
            dim: [
                [0,0], [0,126.27], [13.97,126.27], [13.97,46.27], [22.15,46.27], [22.15,0]
            ],
            key: 0
        },
        d: {
            dim: [
                [0,0], [0,46.27], [2.82,46.27], [2.82,126.27], [16.79,126.27], [16.79,46.27], [22.15,46.27], [22.15,0]
            ],
            key: 2
        },
        e: {
            dim: [
                [0,0], [0,46.27], [8.18,46.27], [8.18,126.27], [22.15,126.27], [22.15,0]
            ],
            key: 4
        },
        f: {
            dim: [
                [0,0], [0,126.27], [12.83,126.27], [12.83,46.27], [22.15,46.27], [22.15,0]
            ],
            key: 5
        },
        g: {
            dim: [
                [0,0], [0,46.27], [1.68,46.27], [1.68,126.27], [14.76,126.27], [14.76,46.27], [22.15,46.27], [22.15,0]
            ],
            key: 7
        },
        a: {
            dim: [
                [0,0], [0,46.27], [7.39,46.27], [7.39,126.27], [20.47,126.27], [20.47,46.27], [22.15,46.27], [22.15,0]
            ],
            key: 9
        },
        b: {
            dim: [
                [0,0], [0,46.27], [9.32,46.27], [9.32,126.27], [22.15,126.27], [22.15,0]
            ],
            key: 11
        }
    }
    
    const convertWhite = () => {
        let newObj = {};
        Object.keys(keyDimensions).forEach(key => {
            let str = '';
            keyDimensions[key].dim.forEach(point => {
                str = str + (point[0] * scale).toString() + ',' + (point[1] * scale).toString() + ' ';
            })
            newObj[key] = {
                dim: str,
                key: keyDimensions[key].key
            }
        })
        return newObj;
    }
    
    const blackPos = {
        db: {
            pos: 13.97,
            key: 1
        },
        eb: {
            pos: 39.94,
            key: 3
        },
        gb: {
            pos: 79.28,
            key: 6
        },
        ab: {
            pos: 104.36,
            key: 8
        },
        bb: {
            pos: 129,
            key: 10
        }
    }
    
    const convertBlack = () => {
        let newObj = {};
        Object.keys(blackPos).forEach(key => {
            newObj[key] = {
                pos: (blackPos[key].pos * scale).toString() + 'px',
                key: blackPos[key].key
            };
        });
        return newObj;
    }

    let wDims = convertWhite();
    let bDims = convertBlack();

    // get highlight color for keys
    // type param should be 'white' or 'black'
    const getColor = (type, keyNum) => {
        if (!(type === 'white' || type === 'black')) {
            throw new Error('type is not black or white!!')
        }
        let color;
        if ((display[keyToString(keyNum)] && notes[keyToString(keyNum)]) || (display[keyToString(keyNum)] && !usePedal)) {
            color = type === 'white' ? highlightColor : highlightColor2;
        } else if (display[keyToString(keyNum)]) {
            color = type === 'white' ? highlightColorPedal : highlightColorPedal2;
        } else {
            color = type;
        }
        return color;
    }

    return (
        <div 
            className="octave" 
            style={{
                height: (127 * scale).toString() + 'px',
                width: (22 * 7 * scale).toString() + 'px'
            }}
        >
            <div className="wk-container">
                {Object.keys(wDims).map((key, i) => {
                    let keyNum = wDims[key].key + base;
                    let color = getColor('white', keyNum)
                    return (
                        <span key={i}>
                            <svg 
                                className={'key ' + key + 'key'} 
                                style={{
                                    transform: 'scaleY(-1)',
                                    width: (22 * scale).toString() + 'px',
                                    height: (126.27 * scale).toString() + 'px',
                                    backgroundColor: color,
                                    borderRadius: (2 * scale).toString() + 'px'
                                }}
                            >
                                <polygon points={wDims[key].dim} style={{fill: color}} />
                            </svg>
                            <span className="key-mask-anchor">
                                <svg
                                    className={'key-mask'}
                                    style={{
                                        transform: 'scaleY(-1)',
                                        left: (-22 * scale).toString() + 'px',
                                        width: (22 * scale).toString() + 'px',
                                        height: (126.27 * scale).toString() + 'px',
                                        borderRadius: (2 * scale).toString() + 'px'
                                    }}
                                    // onClick={() => console.log(toNote(keyNum))}
                                >
                                    <polygon className="key-mask-fill" points={wDims[key].dim} />
                                </svg>
                            </span>
                        </span>
                    )
                })}
            </div>
            <div className="bk-container">
                {Object.keys(bDims).map((key, i) => {
                    let keyNum = bDims[key].key + base;
                    let color = getColor('black', keyNum)
                    return (
                        <div 
                            className="bk-wrapper" 
                            key={i}
                            style={{
                                left: bDims[key].pos, 
                                top: (-2 * scale).toString() + 'px'
                            }}
                        >
                            <div 
                                className="blackKey" 
                                style={{
                                    backgroundColor: color,
                                    width: (14 * scale).toString() + 'px',
                                    height: (82 * scale).toString() + 'px',
                                    borderRadius: (2 * scale).toString() + 'px',
                                }}
                            ></div>
                            <div className="blackKey-mask-anchor">
                                <div
                                    className="blackKey-mask"
                                    style={{
                                        backgroundColor: 'auto',
                                        top: (-82 * scale).toString() + 'px',
                                        width: (14 * scale).toString() + 'px',
                                        height: (82 * scale).toString() + 'px',
                                        borderRadius: (2 * scale).toString() + 'px',
                                    }}
                                    // onClick={() => console.log(toNote(keyNum))}
                                ></div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div 
                className='kb-cNum'
                style={{
                    top: (105 * scale).toString() + 'px',
                    left: (8 * scale).toString() + 'px',
                    fontSize: (0.8 * scale).toString() + 'rem'
                }}
            >{n - 1}</div>
        </div>
    )
}

export default Octave;