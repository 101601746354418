import React, { useState } from "react";
import Keyboard from "../../keyboard/Keyboard";
import { Form, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Warning from "../../warning/Warning";
import "./MidiPage.css";

const MidiPage = () => {
  const [octaveN, setOctaveN] = useState(7);
  const [size, setSize] = useState(0);
  const [showChord, setShowChord] = useState(true);
  const [chord, setChord] = useState("");
  const [usePedal, setUsePedal] = useState(true);
  const [redirect, setRedirect] = useState({
    go: false,
    path: ''
  });

  if (redirect.go) {
    return <Redirect to={redirect.path} />
  }

  return (
    <>
      <Warning
        className='mp-page-sm'
        title={
          <>
            Screen Size Not Supported
          </>
        }
        text={[
          <>
            Sorry about that! Try a larger screen <br/> to use the <i className="icon-midi-display" />MidiDisplay keyboard.
          </>
        ]}
      />
      <div className="mp-page">
        <div className="mp-header">
          <Button variant="link" className="mp-title-button" href="/">
            <h1 className="mp-title">
              <i className="icon-midi-display" />Midi Display
            </h1>
          </Button>
          <div className="mp-settings-container">
            <div className="mp-setting-section">
              <div className="mp-setting">
                <h2 className="mp-setting-title">Octaves</h2>
                <div className="mp-setting-btns">
                  <div>
                    <button
                      className="mp-setting-btn"
                      onClick={() =>
                        setOctaveN((prev) => (prev > 1 ? prev - 1 : prev))
                      }
                    >
                      <i className="bi-chevron-left" />
                    </button>
                  </div>
                  <p className="mp-setting-val mp-octave-setting">{octaveN}</p>
                  <div>
                    <button
                      className="mp-setting-btn"
                      onClick={() =>
                        setOctaveN((prev) => (prev < 7 ? prev + 1 : prev))
                      }
                    >
                      <i className="bi-chevron-right" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mp-setting">
                <h2 className="mp-setting-title">Scale</h2>
                <div className="mp-setting-btns">
                  <div>
                    <button
                      className="mp-setting-btn"
                      onClick={() =>
                        setSize((prev) => (prev > -10 ? prev - 1 : prev))
                      }
                    >
                      <i className="bi-chevron-left" />
                    </button>
                  </div>
                  <p className="mp-setting-val mp-octave-setting">{size}</p>
                  <div>
                    <button
                      className="mp-setting-btn"
                      onClick={() =>
                        setSize((prev) => (prev < 10 ? prev + 1 : prev))
                      }
                    >
                      <i className="bi-chevron-right" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="mp-setting-section">
              <div className="mp-setting">
                <h2 className="mp-setting-title">Show Chord Identifier</h2>
                <Form.Check
                  type="switch"
                  className="mp-setting-switch"
                  checked={showChord}
                  onChange={() => setShowChord((prev) => !prev)}
                />
              </div>
              <div className="mp-setting">
                <h2 className="mp-setting-title">Use Pedal</h2>
                <Form.Check
                  type="switch"
                  className="mp-setting-switch"
                  checked={usePedal}
                  onChange={() => setUsePedal((prev) => !prev)}
                />
              </div>
            </div>
            { showChord && <div className="mp-setting mp-chord-container">
              <h2 className="mp-setting-title">Chord:</h2>
              <div className="mp-chord-text-container">
                <p className="mp-chord-text">{chord}</p>
              </div>
            </div> }
          </div>
        </div>
        <div className="mp-keyboard-wrapper">
          <Keyboard
            className="mp-keyboard"
            octaveN={octaveN}
            size={size}
            showChord={showChord}
            usePedal={usePedal}
            onChordChange={(chrd) => setChord(chrd)}
          />
        </div>
      </div>
    </>
  );
};

export default MidiPage;
