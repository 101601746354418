import React, { useState, useEffect } from 'react';
import { Form, Modal, Button, Spinner } from 'react-bootstrap'
import { fireAuth } from '../../../../Fire';
import Alert, { emptyAlert } from '../../../alert/Alert';
import ForgotPassword from './forgot-password/ForgotPassword';
import { Redirect } from 'react-router-dom';
import { sleep } from '../../../../resources/Functions';
import './LogIn.css';

const LogIn = props => {
    const { handleSwitch, donePath } = props;

    const [validated, setValidated] = useState(false);
    const [state, setState] = useState({
        email: '',
        pw: ''
    })
    const [errors, setErrors] = useState({
        email: 'Please enter a valid email.',
        pw: 'Please enter your password.'
    })
    const [showPwResetModal, setShowPwResetModal] = useState(false)
    const [alert, setAlert] = useState({emptyAlert})
    const [redirect, setRedirect] = useState({
        done: false,
        path: ''
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (fireAuth.currentUser == null) {
            document.getElementById('email').focus();
        }
    }, [])

    const validate = (prop, criteria, errorMessage) => {
        const message = criteria() ? '' : errorMessage;
        document.getElementById(prop).setCustomValidity(message);
        setErrors({...errors, [prop]: message});
    }

    const handleChange = prop => {
        return event => {
            if (prop === 'email') {
                validate(
                    'email',
                    () => /.+@.+\..+/.test(event.target.value),
                    'Please enter a valid email.'
                )
            } else if (prop === 'pw') {
                validate(
                    'pw',
                    () => true,
                    ''
                )
            }
            setState({...state, [prop]: event.target.value});
        }
    }

    const handleLogin = event => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setLoading(true)
            fireAuth.signInWithEmailAndPassword(state.email, state.pw).then(user => {
                // user is signed in
                console.log('path');
                console.log(donePath)
                if (donePath === undefined || donePath === '' || donePath === null) {
                    window.location.reload();
                } else {
                    sleep(500).then(() => {
                        setRedirect({
                            done: true,
                            path: donePath
                        })
                    })
                }
            }).catch(error => {
                setLoading(false);
                if (error.code === "auth/user-not-found") {
                    validate(
                        'email',
                        () => false,
                        'A user with this email does not exist.'
                    )
                } else if (error.code === "auth/wrong-password") {
                    validate(
                        'pw',
                        () => false,
                        'This password is incorrect.'
                    )
                }
            })
        }
        setValidated(true);
    }

    if (redirect.done) {
        return <Redirect to={redirect.path} />
    }

    return <>
        <Form 
            noValidate
            validated={validated}
            onSubmit={handleLogin}
        >
            <Modal.Header closeButton>
                <Modal.Title className="am-title">
                    <i className="icon-midi-display"/> Log In
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        id="email"
                        required
                        type="email"
                        placeholder="Email"
                        value={state.email}
                        onChange={handleChange('email')}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        id="pw"
                        required
                        type="password"
                        placeholder="Password"
                        value={state.pw1}
                        onChange={handleChange('pw')}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.pw}</Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="log-in-create-account-link"
                    variant="link"
                    onClick={() => {

                        setShowPwResetModal(true)
                    }}
                >
                    Forgot Password?
                </Button>
                <Button variant="secondary" onClick={handleSwitch}>Create Account</Button>
                <Button 
                    variant="info" 
                    type="submit" 
                    disabled={loading}
                >
                    {loading ? <Spinner 
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> : <>Done</>}
                </Button>
            </Modal.Footer>
        </Form>
        <ForgotPassword
            show={showPwResetModal}
            handleClose={() => setShowPwResetModal(false)}
            handleDone={() => {
                setShowPwResetModal(false);
                setAlert({
                    show: true,
                    title: "Email sent",
                    message: "A password reset email has been sent",
                    confirmCallback: () => {
                        window.location.reload();
                    }
                })
            }}
        />
        {alert.show && <Alert
            show={alert.show}
            title={alert.title}
            message={alert.message}
            confirmCallback={alert.confirmCallback}
        />}
    </>
}

export default LogIn