import firebase from 'firebase';

// will use firebase emulator if set to true
var useEmulator = true;

// Set the configuration for your app
// TODO: Replace with your project's config object
const firebaseConfig = {
  apiKey: "AIzaSyCS3J96NRvk3HrXX5mHmquxIS_7UeeyKLo",
  authDomain: "midi-display.firebaseapp.com",
  projectId: "midi-display",
  storageBucket: "midi-display.appspot.com",
  messagingSenderId: "486637551450",
  appId: "1:486637551450:web:9c13c462f4e5eb4c7c3d63",
  measurementId: "G-KT1KHDHQG9"
};
firebase.initializeApp(firebaseConfig);

// Get a reference to the database service
export var rtDatabase = firebase.database();
export var fireAuth = firebase.auth();
// export var fireStorage = firebase.storage();
// export var fireFuncs = firebase.functions();
if (window.location.hostname === "localhost" && useEmulator) {
    rtDatabase.useEmulator("localhost", 8001);
    fireAuth.useEmulator("http://localhost:9099");
    // fireStorage.useEmulator("localhost", 9199);
    // fireFuncs.useEmulator("localhost", 5001);
}
